<template>
  <div class="side-bar">
    <logo />
    <button :style="showScrollTopButton" @click="scrollToTop" class="scroll-button stop"><i class="el-icon-caret-top"></i></button>
    <div class="nav-box" >

      <el-scrollbar wrap-class="scrollbar-wrapper" id="scrollbar" ref="scrollbar" @scroll="handleScroll" >
        <ul>
          <li 
            v-for="(item,index) in list" :key="index" 
            :class="{on: activeMenu==item.base}" 
          >
            <router-link key="expand" class="item" :to="item.link">
              <img class="icon" :src="getImageUrl(item.icon)" alt="">
              <img class="icon active" :src="getImageUrl(item.icon_active)" alt="">
              <div class="name">{{ item.name }}</div>
            </router-link>
            <i></i>
          </li>
        </ul>
      </el-scrollbar>
 
    </div>
    <button :style="showScrollBottomButton" @click="scrollToBottom" class="scroll-button sbottom"><i class="el-icon-caret-bottom"></i></button>
    <div class="bottom" :data-id="activeMenu">
      <screenfull id="screenfull" class="icon" />
      <!-- <img src="@/assets/icon-full.png" alt=""> -->
      <span>全屏</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import Screenfull from '@/components/Screenfull'
import { initModule, listAllTenantModule } from '@/api';

export default {
  components: { Logo,Screenfull },
  data() {
    return {
      timerId:0,
      list: [
        {
          name: '首页',
          icon: 'home',
          icon_active: 'home-on',
          link: '/home/index',
          base: '/home',
          moduleCode:"",
        }, {
          name: '开单',
          icon: 'order',
          icon_active: 'order-on',
          link: '/order/index',
          base: '/order',
          moduleCode:"M02",
        }, {
          name: '开卡',
          icon: 'card',
          icon_active: 'card-on',
          link: '/card/index',
          base: '/card',
          moduleCode:"M03",
        },{
          name: '提成',
          icon: 'commission',
          icon_active: 'commission-on',
          link: '/commission/index',
          base: '/commission',
          moduleCode:"M05",
        }, {
          name: '报表',
          icon: 'report',
          icon_active: 'report-on',
          link: '/report/index',
          base: '/report',
          moduleCode:"",
        }, {
          name: '会员',
          icon: 'member',
          icon_active: 'member-on',
          link: '/member2/index',
          base: '/member2',
          moduleCode:"M01",
        }
      ],
      showScrollTopButton:  "opacity: 1",
      showScrollBottomButton: "opacity: 1",
      moduleList: [], // 租户可用模块
    }
  },
  computed: {
    ...mapGetters([
      // 'permission_routes',
      // 'sidebar'
    ]),
    activeMenu() {
      this.checkScrollability();
      const route = this.$route
      const { path } = route
      const match = path.match(/(^\/[^\/]*).*/,"$1")
      return match && match[1] ? match[1] : path;
     
    },
  },
  mounted() {
    let that =this;

    that.loadModuleList();

    that.checkScrollability();
    that = this;
    document.getElementById("scrollbar").addEventListener(
      "scroll",
      () => {
       console.log("scrolll......");
       that.checkScrollability();
      },
      true
    );
    console.log("mounted...");

    // this.timerId = setInterval(()=>{
    //   that.checkScrollability();
    // }, 800);
  },
  unmounted() {
    //  clearInterval(this.timerId);
  },
  methods: {
    async loadModuleList() {
      let initRet = await initModule()
      if(initRet.success){
        let moduleList = await listAllTenantModule();
        if(moduleList.success){
          this.moduleList = moduleList.result;
          console.log("this.moduleList", this.moduleList);
          this.initMenu(this.moduleList);
        }
      }
    },
    initMenu(moduleList) {
      this.list = this.list.filter(item => {
        let moduleCode = item.moduleCode;
        if(moduleCode && moduleCode != ""){
          // 模块可用性判断
          let module = moduleList.find(m => m.moduleCode == moduleCode);
          if(module && module.enable){
            return true;
          }else{
            return false;
          }
        }else{
          // 没设置模块编码的菜单，直接显示
          return true
        }
      });

      console.log("menu list", this.list);
    },
    getImageUrl(icon) {
      return require(`@/assets/sidebar/${icon}.png`);
    },
    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.scrollbar.wrap.scrollTop = 0;
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.scrollbar.wrap.scrollTop = this.$refs.scrollbar.wrap.scrollHeight;
      });
    },
    handleScroll() {
      console.log("handleScroll...");
      this.checkScrollability();
    },
    checkScrollability() {
      let that = this;
      
        if (that.$refs.scrollbar) {
          const wrap = this.$refs.scrollbar.wrap;
          const isScrollable = wrap.scrollHeight > wrap.clientHeight;
          const scrollTop = wrap.scrollTop;
          const scrollBottom = wrap.scrollHeight - wrap.scrollTop - wrap.clientHeight;

          //setTimeout(() => {
          //this.$nextTick(() => {
            that.showScrollTopButton = (isScrollable && scrollTop > 0) ? "opacity: 0.8" : "opacity: 0";
            that.showScrollBottomButton = (isScrollable && scrollBottom > 0) ? "opacity: 0.8" : "opacity: 0";
          //});
          //}, 1000);
          
          // console.log("isScrollable", isScrollable);
          // console.log("showScrollTopButton",  this.showScrollTopButton);
          // console.log("showScrollBottomButton", this.showScrollBottomButton);
        }

    }
  }
}
</script>
<style lang="scss" scoped>

/* 添加到你的样式文件中 */
.scroll-button {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  opacity: 0.8;
}

.scroll-button.stop {
  top: 115px;
  left: 70px;
  transform: translateX(-50%);
}

.scroll-button.sbottom {
  bottom: 70px;
  left: 70px;
  transform: translateX(-50%) !important;
}

.nav-box {
  position: relative;
}

  .side-bar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .nav-box {
      flex: 1;
      overflow: hidden;
      margin-top: -10px;
      :deep(.el-scrollbar) {
        height: 100%;
        overflow-x: hidden;
        .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }
      
    }
    ul {
      text-align: center;
      overflow: hidden;
      padding: 20px 0;
      li {
        position: relative;
        padding: 12px 0;
        margin: 20px 0;
        i {
          opacity: 0;
          &::before,&::after {
            content: "";
            position: absolute;
            top: -39px;
            right: 0;
            width: 40px;
            height: 40px;
            background: url('@/assets/sidebar/icon-corner.png') no-repeat;
            background-size: 100% 100%;
          }
          &::after {
            top: unset;
            bottom: -39px;
            transform: rotate(270deg);
          }
        }
        .item {
          position: relative;
          display: block;
          .icon {
            display: block;
            width: 34px;
            height: 34px;
            margin: 0 auto;
            object-fit: contain;
            &.active {
              display: none;
            }
          }
          .name {
            font-size: 20px;
            font-weight: normal;
            line-height: 22px;
            color: #ffffff;
            margin-top: 10px;
          }
          
        }
        &.on {
          i {
            opacity: 1;
          }
          &::before {
            position: absolute;
            left: 20px;
            top: 0;
            width: 120%;
            height: 100%;
            content:  "";
            border-radius: 50px;
            background: #F2F3F5;
          }
          .item {
            padding-left: 10px;
            .name {
              color: #1661F7;
            }
            .icon {
              display: none;
              &.active {
                display: block;
              }
            }
          }
          
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
      opacity: .5;
      padding: 30px 0;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
  }
</style>