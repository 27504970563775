import service from "@/utils/request"
import Qs from 'qs'

// 数据字段
export function queryDict(key) {
  return service.get('/sys/dict/getDictItems/'+key);
}

//登录
export function login(data) {
  return service.post('/sys/mLogin',data);
}
//退出
export function logout(data) {
  return service.post('/sys/logout',data);
}
// 获取用户可登录租户列表
export function queryTenantList(params) {
  return service.get('/config/mkSysuserTenant/queryListByUsername',{params});
}


// 上传文件
export function upload(params) {
  return service.post('/sys/common/upload',params);
}

// 签名
export function signature(params) {
  return service.put('/order/mkOrder/signature', params);
}

// 修改结算时间
export function editOrderPayTime(params) {
  return service.post('/order/mkOrder/payTimeChange', params);
}

// 获取订单列表
export function queryOrderList(params) {
  return service.get('/order/mkOrder/list',{params});
}
// 获取订单详情
export function getOrderDetail(params) {
  return service.get('/order/mkOrder/list',{params});
}
// 添加订单
export function addOrder(params) {
  return service.post('/order/mkOrder/add',params);
}
// 编辑订单
export function editOrder(params) {
  return service.put('/order/mkOrder/edit',params);
}
// 编辑订单员工
export function editOrderAssistant(params) {
  return service.put('/order/mkOrder/editAssistant',params);
}
// 删除订单
export function delOrder(params) {
  return service.delete('/order/mkOrder/delete',{params});
}
//订单结账
export function payOrder(params){
  return service.post(`/cash/mkQuickCash/add`,params);
}
// 预支付
export function prePayOrder(params){
  return service.post(`/cash/mkQuickCash/prepay`,Qs.stringify(params));
}
// 打印
export function printOrder(params){
  return service.post(`/order/mkOrder/print`,Qs.stringify(params));
}
// 作废
export function cancelOrder(params){
  return service.put(`/order/mkOrder/cancel`, params);
}
// 条码支付收款
export function submitQrPayOrder(params, timeout){
  return service.post(`/cash/mkQuickCash/submitQrPay`,Qs.stringify(params), {timeout: timeout});
}

export function editOrderGoods(params){
  return service.put('/order/mkOrderGoods/edit',params);
}


// 会员列表
export function queryMemberList(params){
  return service.get('/customer/mkCustomer/list',{params});
}
// 会员详情
export function getMemberById(params){
  return service.get('/customer/mkCustomer/queryById',{params});
}
// 添加会员
export function addMember(params) {
  return service.post('/customer/mkCustomer/add',params);
}
// 编辑会员
export function editMember(params) {
  return service.put('/customer/mkCustomer/edit',params);
}

// 会员是否设置过支付密码
export function hasPaymentPassword(params) {
  return service.get('/customer/mkCustomer/hasPaymentPassword',{params});
}

// 验证密码是否正确
export function verifyPaymentPassword(params){
  return service.post('/customer/mkCustomer/verifyPaymentPassword',params);
}

// 获取会员详情
export function getMemberDetail(params) {
  return service.get('/customer/mkCustomer/list',{params});
}


//查询会员已购会员卡
export function getMemberCard(params){
  return service.get('/card/mkCard/listByCustomerId',{params});
}
export function getMemberCard2(params){ // 带分页
  return service.get('/card/mkCard/listByCustomerId2',{params});
}

//会员已购会员卡总数
export function getMemberCardNum(params){
  return service.get('/card/mkCard/statByCustomerId',{params});
}
//已经购买品项
export function getMemberGoods(params){
  return service.get('/order/mkOrderGoods/listHasUsableByCustomer',{params});
}
//品项可用次数
export function getMemberGoodsBalance(params){
  return service.get('/order/mkOrderGoods/statBalanceByCustomer',{params});
}


//品项列表
export function queryGoodsList(params) {
  return service.get('/goods/mkGoods/list',{params});
}
// 品项左边分类
export function queryGoodsSubCate(params){
  return service.get('/sys/dict/getDictItems/goods_sub_category',{params});
}
// 品项上边品牌
export function queryBrandList(params) {
  return service.get('/brand/mkBrand/list',{params});
}
// 品项上边大类
export function queryGoodsCate(params){
  return service.get('/goods/mkGoodsCatalog/list',{params});
}
// 查询品项打折后的价格
export function getGoodsMemberPrice(params){
  return service.get('/goods/mkGoods/queryMemberPrice',{params});
}


//购卡订单列表
export function getCardsList(params){
  return service.get('/card/mkCardOrder/list',{params});
}
//开卡选卡列表
export function getCards(params){
  return service.get('/card/mkCardIssue/list',{params});
}
//购卡订单保存
export function addCardOrder(params){
  return service.post('/card/mkCardOrder/add',params);
}
//购卡订单现金支付
export function cardOrderCashPay(params){
  return service.post('/card/mkCardOrder/cashPay',Qs.stringify(params));
}
//购卡订单pos支付
export function cardOrderPosPay(params){
  return service.post('/card/mkCardOrder/posPay',Qs.stringify(params));
}
// 开卡非系统支付处理
export function cardOrderCashPayEx(params){
  return service.post('card/mkCardOrder/cashPayEx',Qs.stringify(params));
}
//购卡订单支付预处理
export function cardOrderPrePay(params){
  return service.post('/card/mkCardOrder/prepay',Qs.stringify(params));
}
//购卡订单条码支付预处理
export function cardOrderSubmitQrPay(params, timeout){
  return service.post('/card/mkCardOrder/submitQrPay',Qs.stringify(params), {timeout: timeout});
}
// 开卡作废
export function cancelCard(params){
  return service.put('/card/mkCardOrder/cancelOrder',params);
}
// 开卡打印
export function printCard(params){
  return service.post('/card/mkCardOrder/print',Qs.stringify(params));
}
// 开卡签字
export function cardSign(params){
  return service.put('/card/mkCardOrder/signature',params);
}
// 修改结算时间
export function editCardPayTime(params) {
  return service.post('/card/mkCardOrder/payTimeChange', params);
}



// 提成消费单审核
export function orderApprove (params){
  return service.put('/order/mkOrder/approve',params)
}
// 提成消费单取消审核
export function orderCancelApprove (params){
  return service.put('/order/mkOrder/cancelApprove',params)
}
// 提成开卡单审核
export function cardOrderApprove (params){
  return service.put('/card/mkCardOrder/approve',params)
}
// 提成开卡单取消审核
export function cardOrderCancelApprove (params){
  return service.put('/card/mkCardOrder/cancelApprove',params)
}
// 获取消费单业绩表
export function getPerfTableListByOrderGoodsId (params){
  return service.get('/order/mkPerformanceTable/listByOrderGoodsId',{params})
}
// 获取开卡单业绩表
export function getPerfTableListByCardOrderId (params){
  return service.get('/order/mkPerformanceTable/listByCardOrderId',{params})
}
// 保存开卡单业绩
export function saveTableSubmitCardPerf (params){
  return service.post('/order/mkPerformanceTable/submitCardPerf',params)
}



//服务员工
export function queryStaffList(params) {
  return service.get('/sys/user/list',{params});
}

// 服务项目
export function queryServiceList(params) {
  return service.get("/service/mkService/list",{params});
}

// 服务属性计算
export function evalCommission(params){
  return service.post('/order/mkAssistantServiceItem/evaluateCommission',params);
}

// birt报表 responseType: 'blob'
export const ReportSalesSummer=params=>service.get('/report/saleReport/getSalesSummer',{params});
export const ReportSalesSummerFile=params=>service.get('/report/saleReport/getSalesSummerFile',{params});

// 品项左边分类
export const getCategoryL=params=>service.get('/sys/dict/getDictItems/goods_sub_category',{params});

//数据字典
export const getDict=params=>service.get('/sys/dict/getDictItems/'+params);


//会员列表
export const getMemberList=params=>service.get('/customer/mkCustomer/list',{params});



// 报表
//当日销售情况
export const dailyAllSaleStat=params=>service.get('/report/saleReport/dailyAllSaleStat',{params});
//当日销售情况分类统计
export const dailySaleStat=params=>service.get('/report/saleReport/dailySaleStat',{params});
//商品销售排名
export const listGoodsSaleSummary=params=>service.get('/report/saleReport/listGoodsSaleSummary',{params});
// 顾客 
export const statCustomerOrder=params=>service.get('/report/saleReport/statCustomerOrder',{params});
// 销售 
export const pageGoodsSaleSummary=params=>service.get('/report/saleReport/pageGoodsSaleSummary',{params});
// 服务 
export const listDebitGoodsSummary=params=>service.get('/report/saleReport/listDebitGoodsSummary',{params});
// 储值卡 
export const listCardSaleSummary=params=>service.get('/report/saleReport/listCardSaleSummary',{params});
// 首页信息
export const getHomeInfo = params=>service.get('/home/index', {params}); 

// 老系统记录
export const thirdTrxHisList = params=>service.get('/third/mkThirdTrxHis/list', {params}); 
export const thirdTrxHisQueryById =  params=>service.get('/third/mkThirdTrxHis/queryById', {params});

// 到期查询
export const querySubscription =  params=>service.get('/system/mkSaasOrder/querySubscription', {params});



// 租户可用模块
export const initModule = params=>service.get('/system/mkTenantModule/initModule', {params});
export const listAllTenantModule =  params=>service.get('/system/mkTenantModule/listAll', {params});

// 权限
export const listAllPermissionByToken =  params=>service.get('/sys/permission/getUserPermissionByToken', {params});

// 查看订单号是否存在
export function checkSnExists(sn) {
  return service.get(`/order/mkOrder/checkSnExists/${sn}`);
}
export function checkSnExists2(id,sn) {
  return service.get(`/order/mkOrder/checkSnExists/${id}/${sn}`);
}